<template>
  <div class="app-container">
    <eHeader
      ref="header"
      :query="query"
      @signalLoginInput="signalLoginInput"
      @copyLoginInput="copyLoginInput"
      @add="addHandle"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="ID" width="70px" />
      <el-table-column prop="login" label="订阅者MT4" />
      <el-table-column prop="signalLogin" label="信号源MT4" />
      <el-table-column prop="signalUserName" label="信号源昵称" />
      <el-table-column prop="copyType" label="跟随策略">
        <template slot-scope="scope">
          <span>{{getType(scope.row.copyType)}}</span>
          <span>{{scope.row.copyValue}}{{scope.row.copyType==1 ? "手": "倍"}}
             ({{scope.row.copyDirection == 1 ? "正": "反"}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="copyMaxLots" label="最大持仓手数/笔数" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.copyMaxLots || "-"}}/{{scope.row.copyMaxNum || "-"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="跟单状态">
        <template slot-scope="scope">
          <span>{{scope.row.copyStatus==0?'暂停':'正常'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="term" label="有效期" width="250px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.beginDate)+"~"+parseTime(scope.row.expireDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="订阅状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">有效</span>
          <span v-if="scope.row.status==1">失效</span>
          <span v-if="scope.row.status==2">取消订阅</span>
          <span v-if="scope.row.status==3">通讯失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="subscriptionTime" label="订阅时间" width="150px">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.subscriptionTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280px" align="center">
        <template slot-scope="scope">
<!--          <edit v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_EDIT'])" :data="scope.row"  />-->
          <el-button
              v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_EDIT'])"
              size="mini"
              type="success"
              @click="to(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />
    <add-or-edit
        ref="form"
        :is-add="isAdd"
        @add-or-edit-success="addOrEditSuccess"
    ></add-or-edit>
  </div>
</template>

<script>
  import { limitMt4Number } from '@/utils/validate'
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { parseTime } from "@/utils/index";
import eHeader from "@/components/monitor/innerCopy/header";
import addOrEdit from "@/components/monitor/innerCopy/form";
export default {
  name:'innerCopy',
  components: {
    eHeader,
    addOrEdit
  },
  mixins: [initData],
  data() {
    return {
      dialogVisible: false,
      subscriptionTransData: [],
      listParams:{
        login: '',
        signalLogin: '',
      },
      listData:[],
      isAdd: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    signalLoginInput(){
      this.query.signalLogin = limitMt4Number(this.query.signalLogin);
    },
    copyLoginInput(){
      this.query.login = limitMt4Number(this.query.login);
    },
    getType(copyType){
      switch (copyType) {
        case 3:
          return '智能比例';
        case 1:
          return '固定数量';
        case 2:
          return '比例';
      }
    },
    beforeInit() {
      this.url = "community/crm/getInternalSubscriptions";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const login = query.login;
      const signalLogin = query.signalLogin;
      const signalUserName = query.signalUserName;
      const status = query.status;
      if (login) {
        this.params["login"] = login;
      }
      if (signalLogin) {
        this.params["signalLogin"] = signalLogin;
      }
      if (signalUserName) {
        this.params["signalUserName"] = signalUserName;
      }
      if (status || status === 0) {
        this.params["status"] = status;
      }
      return true;
    },
    to(row) {
      const _this = this.$refs.form;
      // 补回 信号源和跟随者字段 todo
      _this.form = {
        id: row.id,
        signalCommunityMtid: row.signalCommunityMtid,
        login: row.login,
        copyType: row.copyType,
        copyMaxLots: row.copyMaxLots,
        copyMaxNum: row.copyMaxNum,
        copyStatus: row.copyStatus.toString(),
        status: row.status.toString(),
        copyDirection: row.copyDirection,
      }
      if(row.copyType == 3){
        _this.samrkCopyValue = row.copyValue;
      }else if(row.copyType == 2){
        _this.rateCopyValue = row.copyValue;
      }else {
        _this.numberCopyValue = row.copyValue;
      }
      _this.signalList = [
        { id: row.signalCommunityMtid, userName: row.signalUserName, login:row.signalLogin, loginOrder: row.signalLoginOrder }
      ];
      this.isAdd = false;
      _this.dialog = true;
    },
    addHandle(){
      this.isAdd = true;
      let _thisForm = this.$refs.form;
      _thisForm.form.signalCommunityMtid = '';
      _thisForm.form.login = '';

      _thisForm.samrkCopyValue = '';
      _thisForm.rateCopyValue = '';
      _thisForm.numberCopyValue = '';

      _thisForm.form.copyMaxLots = '';
      _thisForm.form.copyMaxNum = '';
      _thisForm.form.copyType = 1;
      _thisForm.form.copyDirection = 1;
      _thisForm.form.copyStatus = '1';
      _thisForm.form.status = '0';

      _thisForm.dialog = true;
    },
    addOrEditSuccess(){
      this.init();
    }
  }
};
</script>

<style scoped>
</style>
