import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'community/crm/createInternalSubscription',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'community/crm/updateInternalSubscription',
    method: 'put',
    data
  })
}

/*
*
* userName
* */
export function getSignalList(data){
  return request({
    url: 'community/crm/srCommunityMtaccount?page=0&size=10&sort=cm.id,desc&accType=1&isMock=2&dismount=0&status=20',
    method: 'get',
    params: data
  })
}
